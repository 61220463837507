import Vue from 'vue'
import Vuex from 'vuex'
import {getUinfo} from "../apis/index"
import {getTimes} from "../utils/index"
Vue.use(Vuex)
export default new Vuex.Store({
  namespaced:true,
  state: {
    indexDialog:false,
    show:true,
    uinfo:{},
    sms_number:'',
    templateType:[],
    collapse:false,
    requestUrl:"https://kspjapi.niuniucrm.com/"
  },
  getters: {
  },
  mutations: {
    // 保存用户信息
    info(state,uinfo){
      state.uinfo=uinfo
      state.sms_number=uinfo.sms_number
      localStorage.setItem("uinfo",JSON.stringify(uinfo))
    },
    changeShow(state){
      state.show=!state.show
    },
    // 是否隐藏左边导航
    changeCollapse(state,show){
      state.collapse=show
    },
    hidDialog(state,bool){
      state.indexDialog = bool
    }
  },
  actions: {
    // 获取用户信息
    async getUinfo(context){
      await getUinfo().then(res=>{
        let enddate = res.data.shop_end_date;
        res.data.shop_end_date = getTimes(enddate);
        let date = Math.ceil(
          (enddate*1000 - (new Date()).getTime()) / 1000 / 60 / 60 / 24
        )
        res.data.surplusday = date<1?0:date;
        res.data.kf_image=this.state.requestUrl+res.data.kf_image
        if(!res.data.shop_mobile){
          context.commit('hidDialog',true)
        }
        context.commit("info",res.data)
      }).catch(err=>{})
    },
  },
  modules: {
  }
})
