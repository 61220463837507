<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import {debounce} from "./utils/index"
export default {
  data() {
    return {
      proportion: 1,
    };
  },
  watch: {
    // 监听屏幕尺寸比例变化
    proportion: {
      immediate: true,
      handler(newVal) {
        let html = document.getElementsByTagName("html")[0]
        if (newVal > 1.4) {
          html.style.width = window.outerWidth + "px";
        } else{
          html.removeAttribute("style")
        }
      },
    },
  },
  mounted() {
    window.addEventListener(
      "resize",
      debounce(() => {
        // 不使用window.onresize只能监听一次，使用可以一直监听
        this.proportion = window.outerWidth / window.innerWidth;
      }, 100)
    );
  },
};
</script>
<style lang="scss">
html,
body,
#app {
  padding: 0;
  margin: 0;
  height: 100%;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
