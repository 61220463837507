/**
 * 自定义元素鼠标移动指令
*/
import Vue from "vue"

Vue.directive("move",{
  inserted(el,val,vNode){
    el.addEventListener('mousedown',(e)=>{
      let top = e.clientY - el.offsetTop
      let left = e.clientX - el.offsetLeft
      let move = (event) => {
        el.style.left =event.clientX - left + "px"
        el.style.top =event.clientY - top + "px"
      }
      el.addEventListener("mousemove", move)
      el.addEventListener("mouseup", () => {
        el.removeEventListener("mousemove", move)
      })
    })
  }
})