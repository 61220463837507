import { testsend, getSmsMsg } from "../apis/index"
import { message } from "../assets/js/resetMessage"
import store from "../store/index"
import router from "../router/index"
// 防抖函数
export function debounce(fn, delay) {
  delay = delay || 100
  let timer
  return function () {
    let that = this
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(that, args)
    }, delay);
  }
}
// 节流函数
export function throttle(fn, interval) {
  let last
  let timer
  interval = interval || 100
  return function () {
    let that = this
    let args = arguments
    let now = +new Date()
    if (last && now - last < interval) {
      clearInterval(timer)
      timer = setInterval(() => {
        last = now
        fn.apply(that, args)
      }, interval);
    } else {
      last = now
      fn(that, args)
    }
  }
}
// 时间戳处理函数
export function getTimes(time) {
  let date = new Date(time * 1000)
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  let day = date.getDate()
  day = day < 10 ? `0${day}` : day
  let hours = date.getHours()
  hours = hours < 10 ? `0${hours}` : hours
  let minute = date.getMinutes()
  minute = minute < 10 ? `0${minute}` : minute
  let seconds = date.getSeconds()
  seconds = seconds < 10 ? `0${seconds}` : seconds
  return `${year}-${month}-${day} ${hours}:${minute}:${seconds}`
}
// 获取时分
export function gettime(time) {
  let date = new Date(time);
  let hours = date.getHours();
  hours = hours < 10 ? `0${hours}` : hours;
  let minute = date.getMinutes();
  minute = minute < 10 ? `0${minute}` : minute;
  return `${hours}:${minute}`;
}
// 获取年月日
export function getdate(time) {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  return `${year}-${month}-${day}`;
}
// 封装测试发送
export function testSend(template = {}, tel) {
  const { content, id, status } = template
  if (!store.state.uinfo.shop_mobile) {
    message({
      message: "需绑定手机号才能进行测试发送！",
      type: 'info',
      onClose() {
        router.push("/member/info")
      }
    })
    return
  }
  if (content) {
    if (!id || status !== 2) {
      message({
        message: "请插入审核通过的短信模板进行测试！",
        type: 'warning'
      })
      return
    }
    if (tel) {
      let reg_tel = /^1[3-9]\d{9}$/;
      if (reg_tel.test(tel)) {
        let data = {
          mobile: tel,
          template_id: id
        }
        testsend(data).then(res => {
          message({
            message: res.msg,
            type: "success"
          })
        })
      } else {
        message({
          message: "请输入正确的手机号！",
          type: 'warning'
        })
      }
    } else {
      message({
        message: "手机号码不能为空！",
        type: 'warning'
      })
    }
  } else {
    message({
      message: "短信内容不能为空！",
      type: 'warning'
    })
  }
}
// 根据签名长度修改输入框首行缩进
export function textIndent(that) {
  that.$nextTick(() => {
    let textarea = that.$refs.inp.$refs.textarea;
    let width = that.$refs.label.clientWidth;
    textarea.style.textIndent = width - 10 + "px";
  });
}
// 获取短信剩余
export function getSurplus() {
  getSmsMsg().then(res => {
    let uinfo = store.state.uinfo
    let { smsNumer, wlNumer } = res.data
    uinfo.sms_number = smsNumer
    uinfo.wl_number = wlNumer
    store.commit("info", uinfo)
  }).catch(err => { console.log(err); })
}