import axios from "axios";
import { message } from "../assets/js/resetMessage";
import Qs from 'qs'
import store from "@/store";
const myaxios = new axios.create({})
myaxios.defaults.timeout = 60000
// myaxios.defaults.baseURL="/api"
//请求拦截器
myaxios.interceptors.request.use((config) => {
  config.headers["X-Requested-With"] = 'XMLHttpRequest'
  let noTokenUrl = ["shop/Login/isToken", "shop/Upload_Log/loginLogError"]//不需要token的请求
  if (!noTokenUrl.includes(config.url)) {
    config.headers.token = localStorage.getItem("token")
  }
  if(config.url == 'shop/User/crowdTotal' || config.url == 'shop/User/addCrowd'){
    config.data = Qs.stringify(config.data)//参数序列化
  }
  config.url = store.state.requestUrl + config.url//kwaiapi  kspjapi
  return config
})
// 响应拦截器
myaxios.interceptors.response.use((res) => {
  if (res.status == 200) {
    if (res.data.code == 1) {//成功返回数据
      return Promise.resolve(res.data)
    } else if (res.data.code == 0) {
      let url = res.data.data || res.data.url
      let msg = res.data.msg
      if (msg == "TOKEN已过期，请重新登录" || msg == "TOKEN无效，请重新登录") {//token过期
        message({
          message: res.data.msg,
          type: 'warning',
          onClose() {
            localStorage.clear()
            sessionStorage.clear()
            setTimeout(()=>{
              window.location.href = url;
            },1000)
          }
        })
      } else {
        if (res.config.url.includes("shop/Crowd/pollingCrowdLog")) {//查看人群发送消息状态
          return Promise.reject(res.data)
        }
        message({
          message: res.data.msg || "未知错误！",
          type: 'warning',
        })
      }
      return Promise.reject(res)
    } else {
      if (res.config.url.includes("shop/pay/add")) {//支付宝支付返回表单数据
        return Promise.resolve(res.data)
      } else {
        return Promise.reject(res)
      }
    }
  } else {
    return Promise.reject(res);
  }
}, err => {
  if(err.message=="timeout of 60000ms exceeded"){
    message({
      message: "网络异常，请求超时！",
      type: 'warning',
    })
  }
  let status = err.request.status
  if (status > 499) {
    message({
      message: "数据请求失败！",
      type: 'warning',
    })
  }
  return Promise.reject(err)
})
export default myaxios