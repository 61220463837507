import axios from "./axios"
// 封装get请求
export function get(url, params = {}) {
  return axios({
    method: "get",
    url,
    params
  })
}
// 封装post请求
export function post(url, data = {}) {
  return axios({
    method: "post",
    url,
    data
  })
}